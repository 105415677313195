import React, { useState } from 'react'
import { connect } from 'react-redux'
import { signUp } from 'store/actions/authActions'
import { SignUpForm as Form } from 'components/widgets'
import { useNavigate } from "react-router-dom";

const SignInForm = ({ data, location, auth, authError, signUp }) => {
  let navigate = useNavigate();

  if (auth.uid) {
    navigate("/getting-started/basics", { replace: true });
  }

  const [state, setState] = useState({
    email: ``,
    password: ``,
  })

  const handleChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    signUp(state)
  }

  return (
    <Form
      onChange={handleChange}
      onSubmit={handleSubmit}
      authError={authError}
    />
  )
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signUp: newUser => dispatch(signUp(newUser)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm)
