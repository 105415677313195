import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import logo from './logo.svg';
import 'styles/theme.scss';
import { Sidenav, NoScript, Topnav } from 'components';
import { PrivateRoute } from 'helpers'
import * as Pages from 'pages'
import { Spinner } from 'react-bootstrap';

// Data
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import {
  createFirestoreInstance,
  getFirestore,
  reduxFirestore,
} from 'redux-firestore'
import { ReactReduxFirebaseProvider, getFirebase } from 'react-redux-firebase'
import fbConfig from 'config/firebaseConfig'
// import firebase from 'firebase/app'
import rootReducer from 'store/reducers/rootReducer'

//for render on auth ready
import { useSelector } from 'react-redux'
import { isLoaded } from 'react-redux-firebase'

// Google Tag Manager set up
import TagManager from 'react-gtm-module'

// Toast notifications
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Helmet } from "react-helmet";

const profileSpecificProps = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableRedirectHandling: false,
  resetBeforeLogin: false,
}

function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth)

  if (!isLoaded(auth)) return <Loading />
  return children
}

const Loading = () => <Spinner />

const Footer = () => (
  <>
    <p className="text-center">
      <small className="text-muted">
        © {new Date().getFullYear()} <a href="https://www.wealthsteer.com/">WealthSteer</a>
      </small>
    </p>
    <br />
  </>
)

const GTM = ({ children }) => {
  const auth = useSelector(state => state.firebase.auth)
  const uid = isLoaded(auth) ? auth.uid : null

  TagManager.initialize({
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID,
    preview: process.env.REACT_APP_ENV,
    dataLayer: {
      userId: uid
    }
  })

  return null
}

const App = () => {
  const [state, setState] = useState({
    firebase: null,
    store: null,
    rrfProps: null,
  })

  useEffect(() => {
    const lazyApp = import(`firebase/app`)
    const lazyFirestore = import(`firebase/firestore`)
    const lazyAuth = import(`firebase/auth`)

    Promise.all([lazyApp, lazyFirestore, lazyAuth]).then(([firebase]) => {
      const fb = fbConfig(firebase)

      const store = createStore(
        rootReducer,
        compose(
          applyMiddleware(
            thunk.withExtraArgument({ getFirestore, getFirebase })
          ),
          reduxFirestore(firebase, fb)
        )
      )

      const rrfProps = {
        firebase,
        config: fbConfig,
        config: profileSpecificProps,
        dispatch: store.dispatch,
        createFirestoreInstance,
      }

      setState({
        firebase: firebase,
        store: store,
        rrfProps: rrfProps,
      })
    })
  }, [])

  if (null === state.store) {
    return <Loading />
  }

  return (
    <Provider store={state.store}>
      <Helmet>
      </Helmet>
      <div>
        <link rel="shortcut icon" href="/favicon/favicon.ico" type="image/x-icon" />
      </div>
      <NoScript />
      <ToastContainer position="bottom-right" />
      <ReactReduxFirebaseProvider {...state.rrfProps}>
        {/*<GTM />*/}
        <AuthIsLoaded>
          <BrowserRouter>
            <Topnav />
            <Routes>
              {/* Unprotected Routes */}
              <Route path="/forgot-password" element={<Pages.ForgotPassword />} />
              <Route path="/sign-in" element={<Pages.SignIn />} />

              {/* Protected Routes */}
              <Route path="/" element={<PrivateRoute component={Pages.Dashboard} />} />
              <Route path="users" element={<PrivateRoute component={Pages.Users} />}>

              </Route>
              <Route path="/users/:id" element={<Pages.UsersProfile test={`ello`} />} />
              <Route path="profile" element={<PrivateRoute component={Pages.Profile} />}>
                <Route path="basics" element={<Pages.ProfileBasics />} />
                <Route path="income" element={<Pages.ProfileIncome />} />
                <Route index element={<Navigate to="/profile/basics" />} />
              </Route>
              <Route path="dev">
                <Route path="calculators" element={<Pages.Dev.Calculators />} />
                <Route path="graph-breakdowns" element={<Pages.Dev.GraphBreakdowns />} />
              </Route>
              {/* 404 */}
              <Route path="*" element={<Pages.NotFound />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </AuthIsLoaded>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
}

export default App;
