export const signIn = credentials => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase()

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({ type: `LOGIN_SUCCESS` })
      })
      .catch(err => {
        dispatch({ type: `LOGIN_ERROR`, err })
      })
  }
}

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase()

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: `SIGNOUT_SUCCESS` })
      })
      .catch(err => {
        dispatch({ type: `SIGNOUT_ERROR`, err })
      })
  }
}

export const signUp = newUser => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase()
    const firestore = getFirestore()

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      // .then((res) => {
      //   return firestore.collection('users').doc(res.user.uid).set({
      //     firstName: newUser.firstName,
      //     lastName: newUser.lastName,
      //   })
      // })
      .then(() => {
        dispatch({ type: `SIGNUP_SUCCESS` })
      })
      .catch(err => {
        dispatch({ type: `SIGNUP_ERROR`, err })
      })
  }
}

export const forgotPassword = ({ email }) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase()

    const actionCodeSettings = {
      url: `${process.env.REACT_APP_APP_DOMAIN}/sign-in?email=${email}&action=password-reset-successful`,
      handleCodeInApp: false,
    }

    firebase
      .auth()
      .sendPasswordResetEmail(email, actionCodeSettings)
      .then(() => {
        dispatch({ type: `FORGOT_PASSWORD_SUCCESS`, email })
      })
      .catch(err => {
        dispatch({ type: `FORGOT_PASSWORD_ERROR`, err })
      })
  }
}
