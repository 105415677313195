import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const AccountTable = ({ heading, columns, data, addNewPath, noData = `` }) => (
  <div className="card">
    {heading && (
      <div className="card-header">
        <h4 className="card-header-title">{heading}</h4>
      </div>
    )}
    <div className="table-responsive">
      <table className="table table-sm table-nowrap card-table">
        <thead>
          <tr>
            {columns.map((col, i) => (
              <th key={i}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody className="font-size-base">
          {data.map((row, i) => (
            <tr key={i}>
              {row.map(cell => (
                <td>{cell}</td>
              ))}
            </tr>
          ))}

          <tr>
            <td colSpan={columns.length} className="text-left">
              {addNewPath && (
              <Link to={addNewPath} className="btn btn-primary mb-2">
                Add new
              </Link>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
)

export default AccountTable
