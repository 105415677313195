import React from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'react-bootstrap'
import { Header } from 'components'
import { DasboardForm } from 'components/widgets'
import { HashLink } from 'react-router-hash-link';

import {
  AccountWidget,
  ActionsWidget,
  DashboardHeader,
  DashboardMarketingWidget,
  CashflowChart,
  DebtOverview,
  InvestmentsOverview,
  EmergencyFundOverview,
  EmergencyFundWidget,
  NotificationTimelineWidget,
  PensionOverview,
  PropertyOverview,
  SavingsOverview,
} from 'components/widgets';

const Dashboard = ({
  overview,
  savingsAndInvestments,
  debts,
  pensions,
  property,
  insurances,
  deleteAccount,
  accounts,
  chartData
}) => {
  return (
    <div className="main-content">
      <DashboardHeader data={chartData} {...overview} />
      <Container className="mt-n6">
        <Row>
          <Col xs={12} lg={6} xl>
            <HashLink to="#policies-and-assets">
              <InvestmentsOverview value={overview.investments} />
            </HashLink>
          </Col>
          <Col xs={12} lg={6} xl>
            <HashLink to="#policies-and-assets">
              <PropertyOverview value={overview.property} />
            </HashLink>
          </Col>
          <Col xs={12} lg={6} xl>
            <HashLink to="#policies-and-assets">
              <DebtOverview value={overview.debt} />
            </HashLink>
          </Col>
          <Col xs={12} lg={6} xl>
            <HashLink to="#policies-and-assets">
              <SavingsOverview value={overview.savings} />
            </HashLink>
          </Col>
          {/*
            We might need to hide pension overview depending on pension types
          */}
          {true && (
            <Col xs={12} lg={6} xl>
              <HashLink to="#policies-and-assets">
                <PensionOverview value={overview.retirementFund} />
              </HashLink>
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={12} xl={6}>
            <CashflowChart data={chartData.cashflow} />
          </Col>
          <Col xs={12} xl={6}>
            <NotificationTimelineWidget />
          </Col>
         {/* <Col xs={12} xl={6}>
            <CashflowChart />
            <EmergencyFundWidget />
          </Col>
          <Col xs={12} xl={4}>
            <ActionsWidget />
          </Col>*/}
        </Row>
        <Row>
          <Header id="policies-and-assets">
            <Header.Body>
              <Header.Pretitle>Overview</Header.Pretitle>
              <Header.Title>Policies & Assets</Header.Title>
            </Header.Body>
          </Header>
        </Row>
        <Row>
          <Col xs={12} xl={4} md={4}  className="mb-5">
              <AccountWidget
                id="savings-and-investments"
                path="/finances/savings-and-investments"
                title="Savings & Investments" accounts={savingsAndInvestments}
                deleteAccount={deleteAccount}
              />
          </Col>
          <Col xs={12} xl={4} md={4}  className="mb-5">
            <AccountWidget
              id="debts"
              path="/finances/debts"
              title="Debts" accounts={debts}
              deleteAccount={deleteAccount}
            />
          </Col>
          <Col xs={12} xl={4} md={4}  className="mb-5">
            <AccountWidget
              id="pensions"
              path="/finances/pensions"
              title="Pensions" accounts={pensions}
              deleteAccount={deleteAccount}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={4}  className="mb-5">
            <AccountWidget
              id="properties"
              path="/finances/properties"
              title="Property" accounts={property}
              deleteAccount={deleteAccount}
            />
          </Col>
          <Col xs={12} xl={4}  className="mb-5">
            <AccountWidget
              id="insurance"
              path="/finances/insurance"
              title="Insurance" accounts={insurances}
              deleteAccount={deleteAccount}
            />
          </Col>
          <Col xs={12} xl={4}  className="mb-5">
            <DashboardMarketingWidget />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

Dashboard.propTypes = {}

export default Dashboard
