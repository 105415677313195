import React from 'react'
import PropTypes from 'prop-types'
import { Dashboard } from 'components'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { deleteAccount } from 'store/actions/financesActions'
import { Spinner, Row, Container, Col } from 'react-bootstrap'

import {UsersTable} from 'components/widgets'

const UsersPage = ({
  auth,
  profile,
  users,
}) => {
  if (!users) {
    return <Spinner />
  }

  const pagesOptions = [
    { value: 5, label: '5 per page' },
    { value: 10, label: '10 per page' },
    { value: -1, label: 'All' },
  ];

  const titleOptions = [
    { value: '*', label: 'Any' },
    { value: 'designer', label: 'Designer' },
    { value: 'developer', label: 'Developer' },
    { value: 'owner', label: 'Owner' },
    { value: 'founder', label: 'Founder' },
  ];

  const leadScoreOptions = [
    { value: '-1', label: 'Any' },
    { value: '1', label: '1+' },
    { value: '2', label: '2+' },
    { value: '3', label: '3+' },
    { value: '4', label: '4+' },
    { value: '5', label: '5+' },
    { value: '6', label: '6+' },
    { value: '7', label: '7+' },
    { value: '8', label: '8+' },
    { value: '9', label: '9+' },
    { value: '10', label: '10' },
  ];

  const contacts = []

  return (
    <div className="main-content">
      <Container fluid>
        <Row className="justify-content-center">
          <Col xs={12} lg={10} xl={8} className="py-6">
            {/*<CRMContactsHeader activeTab={activeTab} setActiveTab={setActiveTab} />*/}
              <UsersTable
                users={users}
                leadScoreOptions={leadScoreOptions}
                pagesOptions={pagesOptions}
                titleOptions={titleOptions}
              />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

UsersPage.propTypes = {}

const mapStateToProps = state => {
  return {
    users: state.firestore.ordered.users
      ? state.firestore.ordered.users
      : [],
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // deleteAccount: id => dispatch(deleteAccount(id)),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    if (!props.auth.uid) return []
    return [
      {
        collection: `users`,
        // where: [["uid", "==", props.auth.uid]],
        // orderBy: [`accountType`, `desc`],
      },
    ]
  })
)(UsersPage)
