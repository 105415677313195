const success = `Saved successfully.`

export const saveBasics = basics => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to database
    const firestore = getFirestore()
    const profile = getState().firebase.profile
    const uid = getState().firebase.auth.uid

    firestore
      .collection("users")
      .doc(uid)
      .set(
        {
          ...basics.state,
          basicsComplete: true,
          userId: uid,
        },
        { merge: true }
      )
      .then((res) => {
        dispatch({ type: `SAVE_BASICS`, success, basics })
      })
      .then(res => {
        // navigate(`/account/wizard/income`)
      })
      .catch(err => {
        dispatch({ type: `SAVE_BASICS_ERROR`, err })
      })
  }
}

export const saveIncome = income => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to database
    const firestore = getFirestore()
    const profile = getState().firebase.profile
    const uid = getState().firebase.auth.uid

    firestore
      .collection("users")
      .doc(uid)
      .set(
        {
          ...income.state,
          incomeComplete: true,
          userId: uid,
        },
        { merge: true }
      )
      .then(() => {
        dispatch({ type: `SAVE_INCOME`, income })
      })
      .catch(err => {
        dispatch({ type: `SAVE_INCOME_ERROR`, err })
      })
  }
}
