const initState = {
  authError: null,
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case `LOGIN_ERROR`:
      return {
        ...state,
        authError: `Login failed`,
      }
    case `LOGIN_SUCCESS`:
      return {
        ...state,
        authError: null,
      }
    case `SIGNOUT_SUCCESS`:
      return state
    case `SIGNUP_SUCCESS`:
      return {
        ...state,
        authError: null,
      }
    case `SIGNUP_ERROR`:
      return {
        ...state,
        authError: action.err.message,
      }
    case `FORGOT_PASSWORD_SUCCESS`:
      return {
        ...state,
        success: `Password reset sent. Please check your emails.`,
        authError: null,
      }
    case `FORGOT_PASSWORD_ERROR`:
      return {
        ...state,
        authError: action.err.message,
      }

    default:
      return state
  }
}

export default authReducer
