import React from 'react'
import PropTypes from 'prop-types'
import { Dashboard } from 'components'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { deleteAccount } from 'store/actions/financesActions'
import { Spinner, Row, Container, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { formatCurrency } from "helpers"
import { UsersTable } from 'components/widgets'
import { AccountTable } from 'components'
import { ModalAccountOverview } from 'components/modals'


const formatAccount = (account) => {
  switch (account.accountType) {
    case 'SAVING_INVESTMENT':
      return [
        formatCurrency(account.balance),
        formatCurrency(account.monthlySavings),
         account.type,
         account.accountHolder,
        <ModalAccountOverview buttonText={``} account={account} />,
      ]
    case 'DEBT':
      return [
        account.lender,
        account.type,
        formatCurrency(account.balance),
        formatCurrency(account.monthlyCost),
        `${account.remainingTerm} ${account.remainingTermFrequency}`,
        <ModalAccountOverview buttonText={``} account={account} />,
      ]
    case 'PENSION':
      const balance =
        `Defined Contribution` === account.type
          ? formatCurrency(account.value)
          : formatCurrency(account.yearlyaccount)

      const provider =
        `Other` === account.provider ? account.providerOther : account.provider

      return [
        provider,
        balance,
        formatCurrency(account.monthlyPersonalContributions + account.monthlyWorkContributions),
        <ModalAccountOverview buttonText={``} account={account} />,
      ]
    case 'PROPERTY':
      return [
        <a href={`/finances/properties/${account.id}`}>{account.name}</a>,
        account.propertyType,
        formatCurrency(account.propertyValue),
        formatCurrency(account.montlyIncome),
        <ModalAccountOverview buttonText={``} account={account} />,
      ]
    case 'INSURANCE':
      return [
        account.provider,
        account.insuranceType,
        formatCurrency(account.policyValue),
        <ModalAccountOverview buttonText={``} account={account} />,
      ]
    default:
      return {}
  }
}

const UsersPage = ({
  auth,
  user
}) => {
  const { accounts, profile } = user
  if (!accounts) {
    return <Spinner />
  }

  const aaa = accounts.reduce(
    (acc, value) => {
      acc[value.accountType].push(formatAccount(value))

      return acc
    },
    {
      DEBT: [],
      INSURANCE: [],
      PENSION: [],
      SAVING_INVESTMENT: [],
      PROPERTY: [],
    }
  )

  return (
    <div className="main-content">
      <Container fluid>
        <Row className="justify-content-center">
          <Col xs={12} lg={10} xl={8} className="py-6">
            <pre class="card card-body bg-light">
              {JSON.stringify(profile, null, 4)}
            </pre>
            {/*<pre class="card card-body bg-light">
              {JSON.stringify(accounts, null, 4)}
            </pre>*/}
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col xs={12} lg={10} xl={8}>
            <AccountTable
              heading="Savings & Investments"
              columns={[
                `Balance`,
                `Monthly Savings`,
                `Account Type`,
                `Account Holder`,
                `Actions`,
              ]}
              data={aaa.SAVING_INVESTMENT}
              noData="No data"
              addNewPath={null}
            />
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col xs={12} lg={10} xl={8}>
            <AccountTable
              heading="Debts"
              columns={[
                `Lender`,
                `Debt Type`,
                `Balance`,
                `Monthly Cost`,
                `Remaining Term`,
                `Actions`
              ]}
              data={aaa.DEBT}
              noData="No data"
              addNewPath={null}
            />
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col xs={12} lg={10} xl={8}>
            <AccountTable
              heading="Pensions"
              columns={[
                `Provider`,
                `Balance / Yearly Pension (pa)`,
                `Monthly Contributions`,
                `Actions`
              ]}
              data={aaa.PENSION}
              noData="No data"
              addNewPath={null}
            />
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col xs={12} lg={10} xl={8}>
            <AccountTable
              heading="Property"
              columns={[
                `Name`,
                `Property Type`,
                `Property Value`,
                `Rental Income`,
                `Actions`,
              ]}
              data={aaa.PROPERTY}
              noData="No data"
              addNewPath={null}
            />
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col xs={12} lg={10} xl={8}>
            <AccountTable
              heading="Insurance"
              columns={[
                `Provider`,
                `Insurance`,
                `Type`,
                `Amount`,
                `Actions`
              ]}
              data={aaa.INSURANCE}
              noData="No data"
              addNewPath={null}
            />
          </Col>
        </Row>

      </Container>
    </div>
  )
}

UsersPage.propTypes = {}

const mapStateToProps = state => {
  console.log('STATE', state.firestore.ordered)
  return {
    user: {
      profile: state.firestore.ordered.users
        ? state.firestore.ordered.users[0]
        : [],
      accounts: state.firestore.ordered.accounts
        ? state.firestore.ordered.accounts
        : [],
    },
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // deleteAccount: id => dispatch(deleteAccount(id)),
  }
}

const ComposedUsersPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    if (!props.auth.uid || !props.userId) return []

    return [
      {
        collection: `accounts`,
        where: [["uid", "==", props.userId]],
        orderBy: [`accountType`, `desc`],
      },
      {
        collection: `users`,
        where: [["userId", "==", props.userId]],
        // orderBy: [`accountType`, `desc`],
      },
    ]
  })
)(UsersPage)


const UserPageContainer = (props) => {
  const { id } = useParams();

  return <ComposedUsersPage userId={id} {...props} />
}

export default UserPageContainer
