import React from 'react'
import PropTypes from 'prop-types'
import { Dashboard } from 'components'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { deleteAccount } from 'store/actions/financesActions'
import { Col, Container, Row, Spinner } from 'react-bootstrap'

const DashboardPage = ({
  auth,
  profile,
  users,
}) => {
  if (!users) {
    return <Spinner />
  }



  return (
    <div className="main-content">
      <Container fluid>
        <Row className="justify-content-center">
          <Col xs={12} lg={10} xl={8} className="py-6">
            {/* Can't believe marquee tags still exists */}
            <marquee
              behavior="alternate"
              truespeed="300"
              scrollamount="10"
            >
              <h2>Admin Dashboard</h2>
            </marquee>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

DashboardPage.propTypes = {}

const mapStateToProps = state => {
  return {
    users: state.firestore.ordered.users
      ? state.firestore.ordered.users
      : [],
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // deleteAccount: id => dispatch(deleteAccount(id)),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    if (!props.auth.uid) return []

    console.log(props.auth)

    return [
      {
        collection: `users`,
        // where: [["uid", "==", props.auth.uid]],
        // orderBy: [`accountType`, `desc`],
      },
    ]
  })
)(DashboardPage)
