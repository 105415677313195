import { useState, useEffect } from 'react'
import { getDefaultState } from 'config/schemas'
import { useNavigate } from "react-router-dom";

const useForm = ({ initialState, schema, onSubmit, onSubmitProps, onSuccess }) => {
  const navigate = useNavigate();
  const [state, setState] = useState(getDefaultState(schema, initialState))
  const [errors, setErrors] = useState([])

  const handleChange = e => {
    const key = e.target.name

    if (key in errors) {
      delete errors[key]
      setErrors(errors)
    }

    switch (e.target.type) {
      default:
        setState({
          ...state,
          [key]: e.target.value,
        })
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    const { error } = schema.validator.validate(state, { abortEarly: false })

    if (null === error) {
      setErrors({})
      onSubmit({
        state,
        ...onSubmitProps
      })

      if (onSuccess) {
        onSuccess(state)
      }
    } else {
      setErrors(
        error.details.reduce((acc, error) => {
          return {
            ...acc,
            [error.path[0]]: error.message,
          }
        }, {})
      )
    }
  }

  return {
    state,
    errors,
    handleChange,
    handleSubmit,
  }
}

export default useForm
