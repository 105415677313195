import { Modal } from 'containers'
import { Table } from 'react-bootstrap'

const camelCaseToProper = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

const formatValue = (key, value) => {
  switch (key) {
    case `createdAt`:
      console.log(value.toString())
      return null;
    default:
      return value;
  }
}
const ModalAccountOverview = ({ buttonText, account }) => {
  const rows = []

  for (const key in account) {
    rows.push(
      <tr>
        <th>{camelCaseToProper(key)}</th>
        <td>{formatValue(key, account[key])}</td>
      </tr>
    )
  }

  return (
    <Modal buttonText={buttonText} heading="">
      <Table size="sm" responsive bordered>
        <thead>
          {rows}
        </thead>
      </Table>
    </Modal>
  )
}

export default ModalAccountOverview
